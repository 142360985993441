// Beer slider
jQuery(document).ready(function () {
  const swiper = new Swiper("#swiper_posts_beer-slider", {
    // Optional parameters
    direction: "horizontal",
    spaceBetween: 60,
    loop: false,
    centeredSlides: false,

    breakpoints: {
      100: {
        slidesPerView: 2,
        spaceBetween: 5,
      },
      780: {
        slidesPerView: 2,
        spaceBetween: 5,
      },
      820: {
        slidesPerView: 3,
        spaceBetween: 15,
      },
      1000: {
        slidesPerView: 3.5,
        spaceBetween: 15,
      },
      1300: {
        slidesPerView: 4.5,
        spaceBetween: 15,
      },
      1600: {
        slidesPerView: 5.5,
        spaceBetween: 15,
      },
    },

    // If we need pagination
    pagination: {
      el: "#swiper_posts_beer-slider .swiper-pagination",
      clickable: true,
    },

    // Navigation arrows
    navigation: {
      nextEl: ".swiper_posts_beer-slider .swiper-button-next",
      prevEl: ".swiper_posts_beer-slider .swiper-button-prev",
    },
  });
});

jQuery(document).ready(function () {
  // Sticky elements.

  jQuery(document).on("facetwp-loaded", function () {
    jQuery(".flexible-grid-menu").each(function () {
      var origin_left = true;
      if (jQuery(this).attr("data-origin-left") == "true") {
        origin_left = false;
      } else {
        origin_left = true;
      }

      // Portfolio masonry layout
      var $grid = jQuery(this).masonry({
        // options
        itemSelector: ".grid-item",
        percentPosition: true,
        columnWidth: ".grid-sizer",
        horizontalOrder: false,
        originLeft: origin_left,
      });

      // layout Masonry after each image loads
      $grid.imagesLoaded().progress(function () {
        $grid.masonry("layout");
      });
    });
  });
});

// Let's go swimming
jQuery(document).ready(function () {
  jQuery(".lets-swim").on("click", function () {
    jQuery(".footer").toggleClass("swimming");
  });
});

/*
var sticky_services_stuck = false;
var sticky_services_from_top = 0;
function updateStickyElements() { 

  var from_top = jQuery(window).scrollTop();

  if (jQuery('.services-floater h2').length > 0) {
    sticky_services_from_top = jQuery('.services-floater').offset().top;
  }

  if (sticky_services_from_top > 0) {
    if (!sticky_services_stuck && from_top >= sticky_services_from_top) {
      jQuery('.services-floater').addClass('sticky--pinned');
      sticky_services_stuck = true;
    }
    else if (sticky_services_stuck && from_top < sticky_services_from_top) {
      jQuery('.services-floater').removeClass('sticky--pinned');
      sticky_services_stuck = false;
    }

    // If it goes beyond the end of our set container, maybe just fade it out or something.
  }

}*/

jQuery(document).ready(function () {
  var age_gate = readCookie("age_gate");
  if (age_gate !== "yes") {
    jQuery(".age-gate-container").addClass("inplace");
    jQuery("body").addClass("age-gate");
  } else {
    console.log("Age gate passed");
  }

  jQuery("#age-gate-yes").click(function (event) {
    createCookie("age_gate", "yes", 30);
    jQuery(".age-gate-container").removeClass("inplace");
    jQuery("body").removeClass("age-gate");

    return false;
  });
});

jQuery(document).ready(function () {
  // IF we are on a single beer page.
  if (jQuery(".beer-single-header .thumb-container").length > 0) {
    // After the image has loaded, let's animate it.
    jQuery(".beer-single-header .thumb-container").imagesLoaded(function () {
      jQuery(".beer-single-header .thumb-container").addClass("inplace");
    });
  }
});


// Show on map pin
jQuery(document).ready(function () {
  jQuery(document).on("facetwp-loaded", function () {
    if (window.FWP_MAP !== undefined) {

      console.log('WE IS HERE');
      jQuery(".view-on-map").on("click", function (e) {
        var post_id = jQuery(this).attr("data-location-id");
        if (
          FWP_MAP.markerLookup[post_id] !== null &&
          FWP_MAP.markerLookup[post_id] !== undefined
        ) {
          var postid = jQuery(this).attr('data-id');
          var marker = FWP_MAP.get_post_markers(post_id);
          jQuery.each( marker, function( key, value ) {
 
            FWP_MAP.map.setCenter({
              lat: value.position.lat(),
              lng: value.position.lng()
            });
            FWP_MAP.is_zooming = true; // Needed if you are using the "Enable map filtering" button
            FWP_MAP.map.setZoom(15); // Set a zoom level between 1 and 20. Make sure it is higher than the marker clusterer's bottom limit.
 
            // google.maps.event.trigger(value, 'click'); // If you don't have spiderfied markers
            google.maps.event.trigger(value, 'spider_click'); // If you have spiderfied markers. Will also work if you don't have spiderfied markers.
 
          });



        }
        return false;
      });
    }
  });
});
